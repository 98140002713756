import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { useProSidebar } from 'react-pro-sidebar';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

import UserModal from 'components/UserModal';
import DateColumn from 'components/DateColumn';

import { useLoading } from 'context/LoadingContext';
import { formatFilter } from 'utils/common';
import api from '../services/axios';

const Usuario = () => {
  const { setLoading } = useLoading();
  const { collapsed } = useProSidebar();

  const [modalOpen, setModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [rowId, setRowId] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5
  });

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });
          
          const { data } = await api.get('/users', { params: filters });

          setTableData(data.users);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      };

      fetchUsers();
    }, 1000);

    return () => clearTimeout(delay);
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);

  const handleOpenCreateModal = () => {
    setRowId(null);
    setUserId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = (row) => {
    setRowId(row.id);
    setUserId(row.getValue('user_id'));
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setRowId(null);
    setUserId(null);
    setModalOpen(false);
  };

  const updateTable = (value) => {
    if (rowId) {
      tableData[rowId] = value;
      setTableData([...tableData]);
    } else {
      tableData.push(value);
      setTableData([...tableData]);
    }
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (!window.confirm(`Confirma exclusão de #${row.getValue('user_id')} - ${row.getValue('name')}`)) return;

      try {
        setLoading(true);
        await api.delete(`/users/${row.getValue('user_id')}`);

        tableData.splice(row.index, 1);
        setTableData([...tableData]);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [tableData, setLoading],
  );

  const columns = useMemo(() => [
    {
      header: 'ID',
      accessorKey: 'user_id',
      enableColumnOrdering: false,
    },
    {
      header: 'Imagem',
      accessorKey: 'image',
      enableSorting: false,
      enableColumnFilter: false,
      Cell: ({ cell }) => <img src={cell.getValue()} alt="User" style={{ borderRadius: "100%", width: '50px', height: '50px' }} />,
    },
    {
      header: 'Nome',
      accessorKey: 'name',
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Telefone',
      accessorKey: 'cell_phone',
    },
    {
      header: 'CPF/CNPJ',
      accessorKey: 'cpf_cnpj',
    },
    {
      header: 'Perfil',
      accessorKey: 'profile.name',
      enableEditing: false,
      enableSorting: false,
    },
    {
      header: 'Plano',
      accessorKey: 'plan.name',
      enableEditing: false,
      enableSorting: false,
    },
    {
      header: 'Endereço',
      accessorKey: 'address',
    },
    {
      header: 'Número',
      accessorKey: 'number',
    },
    {
      header: 'Cidade',
      accessorKey: 'city',
    },
    {
      header: 'Estado',
      accessorKey: 'state',
    },
    {
      header: 'Verificado',
      accessorKey: 'verified',
      filterVariant: 'checkbox',
      accessorFn: (row) => row.verified ? 'Sim' : 'Não',
    },
    {
      header: 'Ativo',
      accessorKey: 'active',
      filterVariant: 'checkbox',
      accessorFn: (row) => row.active ? 'Sim' : 'Não',
    },
    {
      header: 'Contato de Emergência',
      accessorKey: 'emergency_contact',
    },
    {
      header: 'Contato de Emergência (nome)',
      accessorKey: 'emergency_name',
    },
    {
      header: 'Token de Integração',
      accessorKey: 'integration_token',
    },
    {
      header: 'Criado em',
      accessorKey: 'created_at',
      enableEditing: false,
      sortingFn: 'datetime',
      filterFn: 'lessThanOrEqualTo',
      accessorFn: (row) => row.created_at ? new Date(row.created_at) : row.created_at,
      Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`,
      Filter: ({ column }) => (<DateColumn column={column} />),
    },
    {
      header: 'Ultima atualização',
      accessorKey: 'updated_at',
      enableEditing: false,
      sortingFn: 'datetime',
      filterFn: 'lessThanOrEqualTo',
      accessorFn: (row) => row.updated_at ? new Date(row.updated_at) : row.updated_at,
      Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`,
      Filter: ({ column }) => (<DateColumn column={column} />),
    },
  ], []);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: { align: 'center' },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}
        initialState={{
          columnVisibility: { user_id: false },
          columnOrder: [
            'user_id',
            'image',
            'name',
            'email',
            'cell_phone',
            'cpf_cnpj',
            'plan.name',
            'profile.name',            
            'address',
            'city',
            'state',
            'number',
            'verified',
            'active',
            'emergency_name',
            'emergency_contact',
            'integration_token',
            'created_at',
            'updated_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Editar">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Excluir">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="primary" onClick={handleOpenCreateModal} variant="contained">
            Criar Novo
          </Button>
        )}
      />
      <UserModal
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
        userId={userId}
      />
    </>
  );
};

export default Usuario;
