import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import { Sidebar, SubMenu, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PersonIcon from '@mui/icons-material/Person';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ChatIcon from '@mui/icons-material/Chat';
import PaletteIcon from '@mui/icons-material/Palette';
import CampaignIcon from '@mui/icons-material/Campaign';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StarIcon from '@mui/icons-material/Star';
import TvIcon from '@mui/icons-material/Tv';
import GroupIcon from '@mui/icons-material/Group'; 
import EventIcon from '@mui/icons-material/Event';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import HistoryIcon from '@mui/icons-material/History';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { LogoutOutlined } from "@mui/icons-material";


import Usuario from "./pages/Usuario";
import Canal from "./pages/Canal";
import UsuarioCanal from "./pages/UsuarioCanal";
import Veiculo from "./pages/Veiculo";
import TipoVeiculo from "./pages/TipoVeiculo";
import Perfil from "./pages/Perfil";
import Plano from "./pages/Plano";
import Patrocinador from "./pages/Patrocinador";
import Patrocinio from "./pages/Patrocinio";
import Convite from "./pages/Convite";
import Denuncia from "./pages/Denuncia";
import AnaliseDenuncia from "./pages/AnaliseDenuncia";
import Login from "pages/Login";
import Log from "pages/Log";
import Cor from "pages/Cor";
import Categoria from "pages/Categoria"; 

import Cookies from "universal-cookie";
import ProtectedRoutes from "routes/ProtectedRoutes";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import LoadingOverlay from "react-loading-overlay-ts";
import { useLoading } from "context/LoadingContext";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(142, 22, 89)'
    }
  }
});

const cookies = new Cookies();

function App() {
  const { loading } = useLoading();
  const { collapseSidebar } = useProSidebar();
  const token = cookies.get("TOKEN");

  const logout = () => {
    if(window.confirm("Tem certeza que deseja sair?")){
      cookies.remove("TOKEN", {path: "/"});
      window.location.href = "/";
    }
  }

  return (
    <ThemeProvider theme={theme}>    
      <div id="app" style={({ display: "flex", height: "100%", justifyContent: "center" })}>
        <BrowserRouter>
          {token &&
            <Sidebar 
              backgroundColor='rgb(142, 22, 89)' 
              style={{ height: '100vh' }}
            >
              <Menu
                menuItemStyles={{
                  button: {
                    backgroundColor: 'rgb(142, 22, 89)',
                    '&:hover': {
                      backgroundColor: 'rgb(114, 0, 66)',
                    },
                    '&:active': {
                      backgroundColor: 'rgb(200, 82, 139)',
                    },
                  },
                }}
              >

              <MenuItem
                  icon={<MenuOutlinedIcon />}
                  onClick={() => { collapseSidebar(); }}
                  style={{ textAlign: "center" }}
                >
                {" "}
                <h2>Admin</h2>
              </MenuItem>

              <SubMenu icon={<HomeOutlinedIcon />} label="Administrativo">
                <MenuItem icon={<GroupIcon />} component={<Link className="react-link" to="/Usuario" />}>Usuário</MenuItem>
                <MenuItem icon={<LocalShippingIcon />} component={<Link className="react-link" to="/TipoVeiculo" />}>Tipo Veículo</MenuItem>
                <MenuItem icon={<PersonIcon />} component={<Link className="react-link" to="/Perfil" />}>Perfil</MenuItem>
                <MenuItem icon={<AssignmentIcon />} component={<Link className="react-link" to="/Plano" />}>Plano</MenuItem>
              </SubMenu>
              <SubMenu icon={<ChatIcon />} label="Comunicação">
                <MenuItem icon={<TvIcon />} component={<Link className="react-link" to="/Canal" />}>Canal</MenuItem>
                <MenuItem icon={< ContactsOutlinedIcon/>} component={<Link className="react-link" to="/UsuarioCanal" />}>Usuário Canal</MenuItem>
                <MenuItem icon={<DirectionsCarIcon />} component={<Link className="react-link" to="/Veiculo" />}>Veículo</MenuItem>
                <MenuItem icon={<PaletteIcon />} component={<Link className="react-link" to="/Cor" />}>Cor</MenuItem>
              </SubMenu>
              <SubMenu icon={<CampaignIcon />} label="Publicidade">
                <MenuItem icon={<MonetizationOnIcon />} component={<Link className="react-link" to="/Patrocinador" />}>Patrocinador</MenuItem>
                <MenuItem icon={<StarIcon />} component={<Link className="react-link" to="/Patrocinio" />}>Patrocínio</MenuItem>
              </SubMenu>
              <SubMenu icon={<HelpOutlineOutlinedIcon />} label="Gestão">
                <MenuItem icon={<EventIcon />} component={<Link className="react-link" to="/Convite" />}>Convites</MenuItem>
                <MenuItem icon={<ReportProblemIcon />} component={<Link className="react-link" to="/Denuncia" />}>Denúncias</MenuItem>
                <MenuItem icon={<HistoryIcon />} component={<Link className="react-link" to="/Logs" />}>Logs</MenuItem>
              </SubMenu>
              <MenuItem icon={<LogoutOutlined />} onClick={logout}>Logout</MenuItem>
            </Menu>
          </Sidebar>
        }

        <LoadingOverlay
          active={loading}
          spinner
          text='Carregando...'
          styles={{ 
            overlay: (base) => ({ ...base, height: document.documentElement.scrollHeight })
          }}
        >
          <Routes>
            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Login />}></Route>
            </Route>
            
            <Route element={<ProtectedRoutes isAuth />}>
              <Route path="/Usuario" element={<Usuario />}></Route>
              <Route path="/TipoVeiculo" element={<TipoVeiculo />}></Route>
              <Route path="/Perfil" element={<Perfil />}></Route>
              <Route path="/Canal" element={<Canal />}></Route>
              <Route path="/UsuarioCanal" element={<UsuarioCanal />}></Route>
              <Route path="/Veiculo" element={<Veiculo />}></Route>
              <Route path="/Cor" element={<Cor />}></Route>
              <Route path="/Plano" element={<Plano />}></Route>
              <Route path="/Patrocinador" element={<Patrocinador />}></Route>
              <Route path="/Patrocinio" element={<Patrocinio />}></Route>
              <Route path="/Convite" element={<Convite />}></Route>
              <Route path="/Denuncia" element={<Denuncia />}></Route>
              <Route path="/Logs" element={<Log />}></Route>
            </Route>
          </Routes>
        </LoadingOverlay>

      </BrowserRouter>

      </div>
      <ToastContainer autoClose={3000} />
    </ThemeProvider>
  );
}

export default App;
