import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import CanalModal from 'components/CanalModal';
import { toast } from 'react-toastify';
import { useLoading } from 'context/LoadingContext';
import { useProSidebar } from 'react-pro-sidebar';

const Canal = () => {
  const { collapsed } = useProSidebar();
  const { setLoading } = useLoading();
  const [modalOpen, setModalOpen] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState([]);

  const formatFilter = ({ globalFilter, pagination, sorting }) => {
    return {
      globalFilter,
      page: pagination.pageIndex + 1,
      size: pagination.pageSize,
      sort: sorting.length ? sorting.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',') : undefined,
    };
  };

  const fetchChannels = useCallback(async () => {
    setLoading(true);
    try {
      const filters = formatFilter({ globalFilter, pagination, sorting });
      const { data } = await api.get('/channels', { params: filters });
      setTableData(data.channels);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [globalFilter, pagination, sorting, setLoading]);

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      fetchChannels();
    }, 1000);

    return () => clearTimeout(delay);
  }, [fetchChannels]);

  const handleOpenCreateModal = () => {
    setChannelId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = (row) => {
    setChannelId(row.original.channel_id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setChannelId(null);
    setModalOpen(false);
  };

  const updateTable = (updatedChannel) => {
    if (channelId) {
      const updatedData = tableData.map((channel) =>
        channel.channel_id === updatedChannel.channel_id ? updatedChannel : channel
      );
      setTableData(updatedData);
    } else {
      setTableData([...tableData, updatedChannel]);
    }
  };

  const handleDeleteRow = useCallback(async (row) => {
    if (!window.confirm(`Confirma exclusão de ${row.original.name}`)) return;

    try {
      setLoading(true);
      await api.delete(`/channels/${row.original.channel_id}`);
      const updatedData = tableData.filter((item) => item.channel_id !== row.original.channel_id);
      setTableData(updatedData);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  }, [tableData, setLoading]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'channel_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'name',
        header: 'Nome',
      },
      {
        accessorKey: 'description',
        header: 'Descrição',
      },
      {
        accessorKey: 'image',
        header: 'Imagem',
        Cell: ({ cell }) => <img src={cell.getValue()} alt="Channel" style={{ width: '50px', height: '50px' }} />,
      },
      {
        accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        enableColumnOrdering: false,
        enableEditing: false,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`,
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        initialState={{
          columnVisibility: { channel_id: false },
          columnOrder: [
            'channel_id',
            'name',
            'description',
            'image',
            'created_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="primary"
            onClick={handleOpenCreateModal}
            variant="contained"
          >
            Criar Novo Canal
          </Button>
        )}
      />
      <CanalModal
        open={modalOpen}
        onClose={handleCloseModal}
        channelId={channelId}
        updateTable={updateTable}
      />
    </>
  );
};

export default Canal;
