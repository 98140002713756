import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export const DateColumn = ({ column }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            onChange={(newValue) => column.setFilterValue(newValue)}
            format="DD/MM/YYYY"
            slotProps={{
                textField: {
                    sx: { minWidth: '120px' },
                    variant: 'standard',
                },
            }}
        />
    </LocalizationProvider>
  );
};

export default DateColumn;
