import React, { useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import { toast } from 'react-toastify';
import { useLoading } from 'context/LoadingContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatFilter } from 'utils/common';
import { useProSidebar } from 'react-pro-sidebar';


const Log = () => {
  const { collapsed } = useProSidebar();
  const { setLoading } = useLoading();

  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      const fetchAuditLog = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });

          const { data } = await api.get('/audit_logs', {
            params: filters
          }); 
          setTableData(data.audit_logs);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      }

      fetchAuditLog();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);

  const columns = useMemo(
    () => [
      {
        accessorKey: 'audit_log_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'user.name',
        header: 'Usuário',
      },
      {
        accessorKey: 'channel.name',
        header: 'Canal'
      },
      {
        accessorKey: 'log',
        header: 'Log'
      },
      {
        accessorKey: 'type',
        header: 'Tipo'
      },
      {
        accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`,
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}
        initialState={{
          columnVisibility: { audit_log_id: false, 'user.name': false, 'channel.name': false },
          columnOrder: [
            'audit_log_id',
            'log',
            'type',
            'user.name',
            'channel.name',
            'created_at',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
      />
    </>
  );
};

export default Log;
