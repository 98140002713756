import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { 
    Box,
    Button, 
    IconButton, 
    Tooltip 
  } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import ConviteModal from '../components/ConviteModal'; 
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { formatFilter } from 'utils/common';
import { useProSidebar } from 'react-pro-sidebar';

const statusOptions = [
  { value: 0, label: 'Aguardando Aceite' },
  { value: 1, label: 'Aceito' },
  { value: 2, label: 'Recusado' },
];

const Convite = () => {
  const { collapsed } = useProSidebar();
  const { setLoading } = useLoading();

  const [modalOpen, setModalOpen] = useState(false);
  const [invitationId, setInvitationId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [rowId, setRowId] = useState(null);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      const fetchInvitations = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });
          const { data } = await api.get('/invitations', { params: filters }); 
          setTableData(data.invitations);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        } finally {
          setLoading(false);
        }
      };

      fetchInvitations();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);

  const handleOpenCreateModal = () => {
    setRowId(null);
    setInvitationId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = useCallback((row) => {
    setRowId(row.id);
    setInvitationId(row.original.invitation_id);
    setModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setRowId(null);
    setInvitationId(null);
    setModalOpen(false);
  }, []);

  const updateTable = (value) => {
    if (rowId) {
      tableData[rowId] = value;
      setTableData([...tableData]);
    } else {
      tableData.push(value);
      setTableData([...tableData]);
    }
  }

  const handleDeleteRow = useCallback(
    async (row) => {
      if (!window.confirm(`Confirma exclusão de ${row.getValue('invitation_id')}`)) return;
        
      try {
        setLoading(true);
        await api.delete(`/invitations/${row.getValue('invitation_id')}`);
        setTableData((prevData) => prevData.filter((invitation) => invitation.invitation_id !== row.getValue('invitation_id')));
        toast.success('Convite excluído com sucesso.');
      } catch (err) {
        console.error(err);
        toast.error('Erro ao excluir convite.');
      } finally {
        setLoading(false);
      }
    },
    [setLoading],
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'invitation_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'user.name',
        header: 'Usuário',
        Cell: ({ row }) => row.original.user?.name || 'Usuário Desconhecido',
      },
      {
        accessorKey: 'type',
        header: 'Tipo',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: ({ row }) => {
          const statusValue = row.original.status;
          const statusOption = statusOptions.find(option => option.value == statusValue);
          return statusOption ? statusOption.label : 'Desconhecido';
        },
      },
      {
        accessorFn: (row) => new Date(row.created_at),
        accessorKey: 'created_at',
        header: 'Data de Criação',
        enableEditing: false,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`, 
        filterFn: 'lessThanOrEqualTo',
        sortingFn: 'datetime',
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  sx: { minWidth: '120px' },
                  variant: 'standard',
                },
              }}
            />
          </LocalizationProvider>
        ),
      },
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}

        initialState={{
          columnVisibility: { invitation_id: false },
          columnOrder: [
            'invitation_id',
            'user.name',
            'email',
            'type',
            'status',
            'created_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Editar">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Excluir">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="primary" onClick={handleOpenCreateModal} variant="contained">
            Criar Novo
          </Button>
        )}
      />
      <ConviteModal
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
        InvitationId={invitationId}
      />
    </>
  );
};

export default Convite;
