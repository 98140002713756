import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import PatrocinadorModal from 'components/PatrocinadorModal';
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';
import { useProSidebar } from 'react-pro-sidebar';

const Patrocinador = () => {
  const { collapsed } = useProSidebar();
  const { setLoading } = useLoading();

  const [modalOpen, setModalOpen] = useState(false);
  const [sponsorId, setSponsorId] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const fetchSponsors = async () => {
    setLoading(true);
    try {
      const filters = {
        search: globalFilter,
        page_number: pagination.pageIndex,
        limit: pagination.pageSize,
        sort_by: sorting.length ? sorting[0].id : 'sponsor_id',
        sort_type: sorting.length ? (sorting[0].desc ? 'DESC' : 'ASC') : 'ASC',
      };
      columnFilters.forEach(filter => {
        filters[filter.id] = filter.value;
      });

      const { data } = await api.get('/sponsors', { params: filters });
      setTableData(data.sponsors);
    } catch (err) {
      toast.error(`Erro ao carregar patrocinadores: ${err.message}`);
      console.error(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      fetchSponsors();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [columnFilters, globalFilter, pagination, sorting]);

  const handleOpenCreateModal = () => {
    setRowId(null);
    setSponsorId(null);
    setModalOpen(true);
  };

  const handleOpenEditModal = (row) => {
    setRowId(row.id);
    setSponsorId(row.original.sponsor_id);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setRowId(null);
    setSponsorId(null);
    setModalOpen(false);
  };

  const updateTable = useCallback((value) => {
    if (rowId !== null) {
      const updatedData = tableData.map(row => {
        if (row.sponsor_id === value.sponsor_id) {
          return value;
        }
        return row;
      });
      setTableData(updatedData);
    } else {
      setTableData([...tableData, value]);
    }
    toast.success(`Patrocinador ${rowId !== null ? 'atualizado' : 'criado'} com sucesso`);
  }, [tableData, rowId]);

  const handleDeleteRow = useCallback(async (row) => {
    if (!window.confirm(`Confirma exclusão de ${row.original.sponsor_id}`)) return;

    try {
      setLoading(true);
      await api.delete('/sponsors/' + row.original.sponsor_id);
      const updatedData = tableData.filter(data => data.sponsor_id !== row.original.sponsor_id);
      setTableData(updatedData);
      toast.success('Patrocinador excluído com sucesso');
    } catch (err) {
      toast.error(`Erro ao excluir patrocinador: ${err.message}`);
      console.log(err);
    }
    setLoading(false);
  }, [setLoading, tableData]);

  const columns = useMemo(() => [
    {
      accessorKey: 'sponsor_id',
      header: 'ID',
      enableColumnOrdering: false,
      enableSorting: true,
    },
    {
      accessorKey: 'name',
      header: 'Nome',
      enableSorting: true,
    },
    {
      accessorKey: 'nickname',
      header: 'Apelido',
      enableSorting: true,
    },
    {
      accessorKey: 'cnpj',
      header: 'CNPJ',
      enableSorting: true,
    },
    {
      accessorKey: 'created_at',
      header: 'Data de Criação',
      enableColumnOrdering: false,
      enableSorting: true,
      Cell: ({ cell }) => {
        const dateValue = new Date(cell.getValue());
        if (!isNaN(dateValue.getTime())) {
          return `${dateValue.toLocaleDateString()} ${dateValue.toLocaleTimeString()}`;
        } else {
          return 'Data Inválida';
        }
      },
    },
  ], []);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        editingMode="modal"
        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={tableData.length}
        initialState={{
          columnVisibility: { sponsor_id: false },
          columnOrder: [
            'sponsor_id',
            'name',
            'nickname',
            'cnpj',
            'created_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="primary" onClick={handleOpenCreateModal} variant="contained">
            Criar Novo
          </Button>
        )}
      />
      <PatrocinadorModal
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
        sponsorId={sponsorId}
      />
    </>
  );
};

export default Patrocinador;
