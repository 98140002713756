import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import VehicleModal from 'components/VeiculoModal';
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';
import { useProSidebar } from 'react-pro-sidebar';

const Vehicles = () => {
  const { collapsed } = useProSidebar();
  const { setLoading } = useLoading();

  const [modalOpen, setModalOpen] = useState(false);
  const [vehicleId, setVehicleId] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  const fetchVehicles = useCallback(async () => {
    setLoading(true);
    try {
      const filters = {
        search: globalFilter,
        page_number: pagination.pageIndex,
        limit: pagination.pageSize,
        sort_by: sorting.length ? sorting[0].id : 'vehicle_id',
        sort_type: sorting.length ? (sorting[0].desc ? 'DESC' : 'ASC') : 'ASC',
      };
      columnFilters.forEach(filter => {
        filters[filter.id] = filter.value;
      });

      const { data } = await api.get('/vehicles', { params: filters });
      setTableData(data.vehicles);
      setRowCount(data.count);
    } catch (err) {
      toast.error(err.message);
      console.error(err);
    }
    setLoading(false);
  }, [globalFilter, pagination, sorting, columnFilters, setLoading]);

  useEffect(() => {
    fetchVehicles();
  }, [fetchVehicles]);

  const updateTable = (value) => {
    if (rowId !== null) {
      const updatedData = tableData.map(row => {
        if (row.vehicle_id === value.vehicle_id) {
          return value;
        }
        return row;
      });
      setTableData(updatedData);
    } else {
      setTableData([...tableData, value]);
    }
  };

  const columns = useMemo(
    () => [

      {
        accessorKey: 'vehicle_id',
        header: 'ID',
        enableColumnOrdering: false,
        enableSorting: false,
      },
      {
        accessorKey: 'user.name',
        header: 'Usuário'
      },
      {
        accessorKey: 'brand',
        header: 'Marca',
      },
      {
        accessorKey: 'user.name',
        header: 'Usuário',
      },
      {
        accessorKey: 'color.name',
        header: 'Cor',
      },
      {
        accessorKey: 'model',
        header: 'Modelo',
      },
      {
        accessorKey: 'plate',
        header: 'Placa',
      },
      {
        accessorKey: 'description',
        header: 'Descrição'
      },
      {
        accessorKey: 'vehicle_type.name',
        header: 'Tipo de Veiculo',
      },
    ],
    []
  );

  return (
    <Box>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        localization={MRT_Localization_PT_BR}
        manualFiltering
        manualPagination
        manualSorting
        onGlobalFilterChange={setGlobalFilter}
        onPaginationChange={setPagination}
        onSortingChange={setSorting}
        onColumnFiltersChange={setColumnFilters}
        rowCount={rowCount}
        state={{
          columnFilters,
          globalFilter,
          pagination,
          sorting,
        }} 
         initialState={{ 
          columnVisibility: { sponsorship_id: false },  // * NÃO MOSTRAR CAMPO AO CARREGAR TABELA PRIMEIRA VEZ
          columnOrder: [
            'vehicle_id',
            'user.name',
            'brand',
            'color.name',
            'plate',
            'description',
            'model',
            'user.name',
            'vehicle_type.name',
            'mrt-row-actions', // * AÇÕES
          ],
        }}
        renderTopToolbarCustomActions={() => (
          <Tooltip title="Adicionar Veículo">
            <Button onClick={() => setModalOpen(true)} variant="contained">
              Adicionar Veículo
            </Button>
          </Tooltip>
        )}
        renderRowActions={({ row }) => (
          <Box>
            <Tooltip title="Editar">
              <IconButton
                onClick={() => {
                  setVehicleId(row.original.vehicle_id);
                  setModalOpen(true);
                }}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Excluir">
              <IconButton
                color="error"
                onClick={async () => {
                  try {
                    await api.delete(`/vehicles/${row.original.vehicle_id}`);
                    fetchVehicles();
                  } catch (err) {
                    toast.error(err.message);
                    console.error(err);
                  }
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
      <VehicleModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        vehicleId={vehicleId}
        onSave={fetchVehicles}
        updateTable={updateTable}
      />
    </Box>
  );
};

export default Vehicles;
