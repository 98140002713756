import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { useProSidebar } from 'react-pro-sidebar';
import { toast } from 'react-toastify';

import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';

import PerfilModal from 'components/PerfilModal';
import DateColumn from 'components/DateColumn';

import { useLoading } from 'context/LoadingContext';
import { formatFilter } from 'utils/common';
import api from '../services/axios';

const Profile = () => {
  const { setLoading } = useLoading();
  const { collapsed } = useProSidebar();

  const [modalOpen, setModalOpen] = useState(false);
  const [profileId, setProfileId] = useState(null);
  const [rowId, setRowId] = useState(null);

  const [tableData, setTableData] = useState({});
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

  useEffect(() => {
    setLoading(true);
    const delay = setTimeout(() => {
      const fetchProfile = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });

          const { data } = await api.get('/profiles', { params: filters }); 

          setTableData(data.profiles);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      }

      fetchProfile();
    }, 1000);

    return () => clearTimeout(delay);
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);

  const handleOpenCreateModal = () => {
    setRowId(null);
    setProfileId(null);
    setModalOpen(true);
  }

  const handleOpenEditModal = (row) => {
    setRowId(row.id);
    setProfileId(row.getValue('profile_id'));
    setModalOpen(true);
  }

  const handleCloseModal = () => {
    setRowId(null);
    setProfileId(null);
    setModalOpen(false);
  }

  const updateTable = (value) => {
    if (rowId) {
      tableData[rowId] = value;
      setTableData([...tableData]);
    } else {
      tableData.push(value);
      setTableData([...tableData]);
    }
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      if (!window.confirm(`Confirma exclusão de #${row.getValue('vehicle_type_id')} - ${row.getValue('name')}`)) return;

      try {
        setLoading(true);
        await api.delete(`/profiles/${row.getValue('profile_id')}`);
  
        tableData.splice(row.index, 1);
        setTableData([...tableData]);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    },
    [tableData, setLoading],
  );

    const columns = useMemo(() => [
      {
        header: 'ID',
        accessorKey: 'profile_id',
        enableColumnOrdering: false,
      },
      {
        header: 'Nome',
        accessorKey: 'name',
      },
      {
        header: 'Criado em',
        accessorKey: 'created_at',
        enableEditing: false,
        sortingFn: 'datetime',
        filterFn: 'lessThanOrEqualTo',
        accessorFn: (row) => row.created_at ? new Date(row.created_at) : row.created_at,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`,
        Filter: ({ column }) => (<DateColumn column={column} />),
      },
      {
        header: 'Ultima atualização',
        accessorKey: 'updated_at',
        enableEditing: false,
        sortingFn: 'datetime',
        filterFn: 'lessThanOrEqualTo',
        accessorFn: (row) => row.updated_at ? new Date(row.updated_at) : row.updated_at,
        Cell: ({ cell }) => `${cell.getValue()?.toLocaleDateString()} ${cell.getValue()?.toLocaleTimeString()}`,
        Filter: ({ column }) => (<DateColumn column={column} />),
      },
    ], []);

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: { align: 'center' },
            size: 120,
          },
        }}
        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}
        columns={columns}
        data={tableData}
        editingMode="modal"
        initialState={{ 
          columnVisibility: { profile_id: false },  
          columnOrder: [
            'profile_id',
            'name',
            'created_at',
            'updated_at',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() => handleOpenEditModal(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="primary"
            onClick={handleOpenCreateModal}
            variant="contained"
          >
            Criar Novo
          </Button>
        )}
      />
      <PerfilModal
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
        profileId={profileId}
      />
    </>
  );
};

export default Profile;
