import React, { useCallback, useMemo, useState, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR';
import api from '../services/axios';
import UsuarioCanalModal from 'components/UsuarioCanalModal';
import { useLoading } from 'context/LoadingContext';
import { toast } from 'react-toastify';
import { formatFilter } from 'utils/common';
import { useProSidebar } from 'react-pro-sidebar';

const CanalUsuario = () => {
  const { collapsed } = useProSidebar();
  const { setLoading } = useLoading();

  const [modalOpen, setModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5, 
  });

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(true);
    
      const fetchChannelUsers = async () => {
        try {
          const filters = formatFilter({ columnFilters, globalFilter, pagination, sorting });

          const { data } = await api.get('/channel_users', {
            params: filters
          }); 
          setTableData(data.channel_users);
          setRowCount(data.count);
        } catch (err) {
          toast.error(err.message);
        }
        setLoading(false);
      }

      fetchChannelUsers();
    }, 1000);

    return () => {
      clearTimeout(delay);
    };
  }, [setLoading, columnFilters, globalFilter, pagination, sorting]);

  const handleOpenCreateModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const updateTable = (value) => {
    tableData.push(value);
    setTableData([...tableData]);
  }

  const handleDeleteRow = useCallback(
    async (row) => {
      if (!window.confirm(`Confirma remoção de ${row.getValue('user.name')} do canal ${row.getValue('channel.name')}`)) return;
        
      try {
        setLoading(true);

        const channel_id = row.original.channel_id;
        const user_id = row.original.user_id;

        await api.delete('/channel_users/', {
          data: { channel_id, user_id }
        });

        setTableData((prevTableData) =>
          prevTableData.filter((_, index) => index !== row.index)
        );
        toast.success("Usuário removido com sucesso.");
      } catch (err) {
        toast.error(err.message);
      }
      setLoading(false);
    },
    [setLoading]
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: 'user.name',
        header: 'Nome do Usuário',
      },
      {
        accessorKey: 'channel.name',
        header: 'Nome do Canal'
      },
      {
        accessorKey: 'is_admin',
        header: 'ADMIN',
        Cell: ({ cell }) => cell.getValue() ? 'Sim' : 'Não', 
      }
    ],
    []
  );

  return (
    <>
      <MaterialReactTable
        muiTablePaperProps={{ style: { width: `${collapsed ? 'calc(100vw - 80px - 2px)' : 'calc(100vw - 250px - 2px)'}`, height: '100%' } }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}
        manualFiltering
        manualSorting
        manualPagination
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onSortingChange={setSorting}
        onPaginationChange={setPagination}
        state={{ columnFilters, globalFilter, pagination, sorting }}
        rowCount={rowCount}
        initialState={{
          columnOrder: [
            'user.name',
            'channel.name',
            'is_admin',
            'mrt-row-actions',
          ],
        }}
        enableColumnOrdering
        localization={MRT_Localization_PT_BR}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '0.5rem', justifyContent: 'center' }}>
            <Tooltip arrow placement="right" title="Excluir">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button color="primary" 
          onClick={handleOpenCreateModal} 
          variant="contained"
          >
            Criar Novo
          </Button>
        )}
      />
      <UsuarioCanalModal
        open={modalOpen}
        updateTable={updateTable}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default CanalUsuario;
